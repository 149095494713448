.container{
    height: 100%;
    width: 100%;
    text-align: center;
    color: black;
    border: none;
  
}
.photo--container{
    width: 100%;
    height: 350px;
    border: none;
    
}
.slyder{
    background:#00000011;
    border-radius: 5px;

}
.car--photo{
    height: 100%;
    width:100%;
    margin-bottom: 10px;
    border-radius: 5px;
   transition:  0.5s ease-in-out;
   object-fit: contain;
   margin-bottom: 10px;
}
.button{
    background-color: crimson;
    padding: 10px;
    cursor: pointer;
    outline: none;
    margin-right: 20px;
    margin-top: 10px;
    color: black;
    border-radius: 4px;
    border: none;
    margin-bottom:10px;
}
.button:hover{
    background-color:white;
    color: crimson;
}