header {
    padding: 20px 0;
}

#header-location {
    font-family: system-ui, -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif;
    color:black;
    margin-right: 200px;
    font-size: 0.75em;
    display:flex;
    flex-direction: column;
    flex-wrap:wrap;
    display: hide;
}
.location{ 
    color:red;
    font-size:25px;
    
}
.location:hover{
    color: rgb(2, 7, 86);

}
#header-logo {
    width: 180px;
    padding: 0 20px;
    align-items: center;
    justify-content: center;
    /* transform: translateX(50%); */

    
}

#header-links {
    flex-grow: 1;
    display: flex;
    justify-content: flex-end;
    align-items: center;
    gap: 20px;
}

#header-links a {
    text-decoration: none;
    color: rgb(44, 44, 44);
}

#header-links a:hover {
    color: red;
}

#header-links a.active {
    color: red
}

#header-menu-toggler {
    display: none;
    text-align: right;
    z-index: 1001;
}

@media screen and (max-width: 900px) {
    #header>div {
        flex-wrap: wrap;
    }
   

    #header-location {
        flex-wrap: wrap;
        display: none;
        visibility: collapse;
    
    }
  
 
    #header-logo {
        width: 13px;
        
    }

    #header-links {
        background: linear-gradient(to right, #ffffffcb, #ffffffef, white);
        backdrop-filter: blur(5px);
        flex-direction: column;
        justify-content: center;
        position: fixed;
        top: 0;
        right: 0;
        width: 70%;
        height: 100%;
        z-index: 1000;
        transform: translateX(100%);
        transition: transform 300ms;
    }

    #header-links.show {
        transform: translateX(0);
    }

    #header-menu-toggler {
        display: block;
        flex-grow: 1;
    }
}
